// export enum ReportDataType {
//     Text = 0,
//     LongText = 1,
//     Number = 2,
//     DatePicker = 3,
//     Media = 4,
//     Dropdown = 5,
//     MultipleDropdown = 6,
//     LinkedType = 7,
//     Bool = 8,
//     BoolAndLongText = 9,
//     BoolAndLongTextAndMedia = 10,
//     LongTextAndMedia = 11,
//     TextAndDatePicker = 12,
//     DropdownAndDatePicker = 13,
//     MultipleDropdownAndLongText = 14,
//     BoolAndDropdownAndLongText = 15,
//     DropdownAndLongText = 16,
// }

export default [
    {
        name: "",
        title: "A. Board of Directors",
        description: "Recommended practices shall be as specified by Principle 1 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions: ",
        questions: [
            {
                question: "Does the Board ensure that it acts in good faith in the exercise of its duties and shall ensure that its responsibilities are performed impartially.",
                isRequired: true,
                uniqueName: "R1Q1",
                entryName: "r1q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Does the Board exercise a degree of care and diligence in fulfilling its responsibilities? ",
                isRequired: true,
                uniqueName: "R1Q2",
                entryName: "r1q2",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Does the Board provide oversight and strategic direction of the PFOs by: Establishing the vision, mission and values of the PFOs",
                isRequired: true,
                uniqueName: "R1Q3(a)",
                entryName: "r1q3a",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "Does the Board provide oversight and strategic direction of the PFOs by: Developing annual plans of the Board",
                isRequired: true,
                uniqueName: "R1Q3(b)",
                entryName: "r1q3b",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "Does the Board provide oversight and strategic direction of the PFOs by: Setting long and short term strategies to guide the PFOs",
                isRequired: true,
                uniqueName: "R1Q3(c)",
                entryName: 'r1q3c',
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question: "Does the Board provide oversight and strategic direction of the PFOs by: Establishing policies for governing the PFOs",
                isRequired: true,
                uniqueName: "R1Q3(d)",
                entryName: "r1q3d",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question: "Does the Board provide oversight and strategic direction of the PFOs by: Appointing Non-Executive Directors (NEDs) and Independent NonExecutive Directors (INEDs)",
                isRequired: true,
                uniqueName: "R1Q3(e)",
                entryName: "r1q3e",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 5,
            },
            {
                question:
                    "Does the Board provide oversight and strategic direction of the PFOs by: Appointing a Managing Director/Chief Executive Officer (MD/CEO), Executive Directors (EDs) and Senior Management (Heads of Departments), to oversee the day to day operations of the PFOs",
                isRequired: true,
                uniqueName: "R1Q3(f)",
                entryName: "r1q3f",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 6,
            },
            {
                question:
                    "Does the Board provide oversight and strategic direction of the PFOs by: Developing the Contract of Employment of the MD/CEO and the Executive Directors (EDs) which shall include Terms of Reference (ToR), Tenure and Remuneration",
                isRequired: true,
                uniqueName: "R1Q3(g)",
                entryName: "r1q3g",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 7,
            },
            {
                question:
                    "Does the Board provide oversight and strategic direction of the PFOs by: Ensuring that the Board and its Committees comprise of members with relevant skills, experience and competencies",
                isRequired: true,
                uniqueName: "R1Q3(h)",
                entryName: "r1q3h",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 8,
            },
            {
                question: "Does the Board provide oversight and strategic direction of the PFOs by: Establishing agreed performance targets for Executive Management",
                isRequired: true,
                uniqueName: "R1Q3(i)",
                entryName: "r1q3i",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 9,
            },
            {
                question: "Does the Board provide oversight and strategic direction of the PFOs by: Monitoring and evaluating the performance of Executive Management",
                isRequired: true,
                uniqueName: "R1Q3(j)",
                entryName: "r1q3j",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 10,
            },
            {
                question: "Does the Board provide oversight and strategic direction of the PFOs by: Reporting to Shareholders at Annual General Meetings",
                isRequired: true,
                uniqueName: "R1Q3(k)",
                entryName: "r1q3k",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 11,
            },
            {
                question:
                    "Does the Board provide oversight and strategic direction of the PFOs by: Overseeing the effectiveness and adequacy of the Compliance, Internal Audit & Control and Risk Management functions",
                isRequired: true,
                uniqueName: "R1Q3(l)",
                entryName: "r1q3l",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 12,
            },
            {
                question:
                    "Does the Board provide oversight and strategic direction of the PFOs by: Appointing the Company Secretary, developing his/her ToR and ensuring that the person appointed is able to provide independent professional advice to the Board and Management",
                isRequired: true,
                uniqueName: "R1Q3(m)",
                entryName: "r1q3m",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 13,
            },
            {
                question:
                    "Does the Board provide oversight and strategic direction of the PFOs by: Promoting and overseeing the highest standards of corporate governance within the Board and the PFOs",
                isRequired: true,
                uniqueName: "R1Q3(n)",
                entryName: "r1q3n",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 14,
            },
            {
                question:
                    "Does the Board provide oversight and strategic direction of the PFOs by: Establishing a succession plan, appointment process, induction and regular training process and remuneration structure for both Board and Executive Management (Executive Directors, Non-Executive Directors and Independent Non-Executive Directors)",
                isRequired: true,
                uniqueName: "R1Q3(o)",
                entryName: "r1q3o",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 15,
            },
            {
                question: "Does the Board provide oversight and strategic direction of the PFOs by: Developing Charter and Terms of Reference (ToR) for Management Committees of the PFOs",
                isRequired: true,
                uniqueName: "R1Q3(p)",
                entryName: "r1q3p",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 16,
            },
            {
                question:
                    "Does the Board provide oversight and strategic direction of the PFOs by: Ensuring compliance with the laws of the Federal Republic of Nigeria and subsidiary legislations issued by the Commission",
                isRequired: true,
                uniqueName: "R1Q3(q)",
                entryName: "r1q3q",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 17,
            },
            {
                question: "Do directors undertake diligent analysis of all proposals placed before the Board and act with the level of skill expected from them?",
                isRequired: true,
                uniqueName: "R1Q4",
                entryName: "r1q4",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Do directors make improper or prejudicial use of information acquired as directors and not disclose non-public information except where disclosure is authorized or legally mandated?",
                isRequired: true,
                uniqueName: "R1Q5",
                entryName: "r1q5",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Do directors take advantage of their position as directors for personal gain or to compete with the Pension Fund Operator (PFO)?",
                isRequired: true,
                uniqueName: "R1Q6",
                entryName: "r1q6",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Do directors engage in conduct likely to discredit the PFO, and do they encourage fair dealings by all employees with the PFO’s stakeholders?",
                isRequired: true,
                uniqueName: "R1Q7",
                entryName: "r1q7",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "B. Board Composition and Structure",
        description: "Recommended practices shall be as specified by Principle 2 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question: "Are Members of the Board of Directors of your PFO be appointed by the Board, approved by the Shareholders and the Commission?",
                isRequired: true,
                uniqueName: "R2Q1",
                entryName: "r2q1",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Has the Board developed a policy and criteria for the selection and appointment of Directors?",
                isRequired: true,
                uniqueName: "R2Q2",
                entryName: "r2q2",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Has the Board constituted a Nomination and Governance Committee to make recommendations to the Board on all Board appointments?",
                isRequired: true,
                uniqueName: "R2Q3",
                entryName: "r2q3",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "A person who is not a serving director of the PFOs should not exercise any influence on the decision making of the Board or Management of the PFOs.",
                isRequired: true,
                uniqueName: "R2Q4",
                entryName: "r2q4",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall develop a policy for the onboarding and orientation of new Directors to the Board.",
                isRequired: true,
                uniqueName: "R2Q5",
                entryName: "r2q5",
                orderIndex: 1,
                dataType: 9,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "C. Officers of the Board: Chairman of the Board",
        description: "Recommended practices shall be as specified by Principle 3 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question:
                    "The MD/CEO or any ED shall only be appointed to the position of Chairman or NED of the PFOs in exceptional circumstances and after a cooling off period of at least three years.",
                isRequired: true,
                uniqueName: "R3Q1(a)",
                entryName: "r3q1a",
                orderIndex: 1,
                dataType: 15,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "The Chairman shall hold meetings with the NED without the Executive present at least once annually and on such other occasions as deemed appropriate.",
                isRequired: true,
                uniqueName: "R3Q1(b)",
                entryName: "r3q1b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "The Chairman's responsibilities include: <br /> (i) Acting in the best interest of the PFOs, and in consistence with the position <br /> (ii) Taking an active role in reviewing and approving policies for major initiatives and activities of the PFOs <br /> (iii) Taking an active role in reviewing and approving policies for major initiatives and activities of the PFOs <br /> (iv) Facilitating effective contribution and constructive relations between the EDs and NEDs",
                isRequired: true,
                uniqueName: "R3Q1(c)",
                entryName: "r3q1c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
        ],
    },
    {
        name: "",
        title: "C. Officers of the Board: The Managing Director / Chief Executive Officer ",
        description: "Recommended practices shall be as specified by Principle 4 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question:
                    "The functions and responsibilities of the MD/CEO shall include: <br /> (i) Ensuring that the Management team is effective and operates in a cohesive manner and all Senior Management focus on their key responsibilities. <br /> (ii) Ensuring effective communication with all stakeholders",
                isRequired: true,
                uniqueName: "R4Q1(a)",
                entryName: "r4q1a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "The MD/CEO provides overall leadership for the Management team.",
                isRequired: true,
                uniqueName: "R4Q1(b)",
                entryName: "r4q1b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
        ],
    },
    {
        name: "",
        title: "C. Officers of the Board: Executive Director",
        description: "Recommended practices shall be as specified by Principle 6 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question: "NEDs shall not nominate alternate Directors to represent them at Board and Board Committee Meetings.",
                isRequired: true,
                uniqueName: "R5Q1(a)",
                entryName: "r5q1a",
                orderIndex: 1,
                dataType: 15,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "The responsibilities of the NEDs shall include: <br /> (i) Contributing to the agenda and deliberations of the Board, Board Committees and Annual General Meetings (AGMs). <br /> (ii) Ensuring that they attend all Board and Board Committee Meetings (as applicable)",
                isRequired: true,
                uniqueName: "R5Q1(b)",
                entryName: "r5q1b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
        ],
    },
    {
        name: "",
        title: "C. Officers of the Board: Independent Non-Executive Director",
        description: "Recommended practices shall be as specified by Principle 7 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question:
                    "An INED is one who: <br /> (i) Does not possess a shareholding in the PFOs, its related or associated PFOs. <br /> (ii) Has not been an employee and/or executive management of the PFOs, its parent, related or associated company. <br /> (iii) Does not have an immediate family member (i.e. spouse, child, adopted Page child, step-child, brother, sister or parent) who is, or has been employed by the PFOs or any of its related PFOs within the last three (3) years. <br /> (iv) Has not received any compensation or remuneration from the PFO or its parent company for the past five (5) financial years, apart from Director's fees and allowances. (v) Is not a substantial shareholder, partner or executive officer of any profit making organization to which the PFOs made, or from which the PFOs received significant payments, in the past five (5) financial years. <br /> (vi) Has not served on the Board for more than nine years from the date of his first election. <br /> (vii) Has not been appointed to represent the interest of some shareholders.",
                isRequired: true,
                uniqueName: "R6Q1(a)",
                entryName: "r6q1a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "The PFOs shall not reclassify an existing NED into an INED on the same Board",
                isRequired: true,
                uniqueName: "R6Q2",
                entryName: "r6q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The responsibilities of the INEDs shall include: <br /> (i) Providing the Board with an independent opinion. <br /> (ii) Ensuring that they attend all Board and Board Committee Meetings (as applicable). <br /> (iii) Ensuring and advising on maintenance of corporate governance at all times.",
                isRequired: true,
                uniqueName: "R6Q3",
                entryName: "r6q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "C. Officers of the Board: Company Secretary",
        description: "Recommended practices shall be as specified by Principle 8 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question:
                    "The responsibilities of the Company Secretary (CS) shall include: <br /> (i) Assisting the Chairman and MD/CEO in coordinating activities regarding the Board and Board Committees. <br /> (ii) Ensuring good information flow within the Board, its Committees and Senior Management.",
                isRequired: true,
                uniqueName: "R7Q1",
                entryName: "r7q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "D. Board Meetings",
        description: "Recommended practices shall be as specified by Principle 10 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question:
                    "Written notices, including agenda of the meeting, shall be circulated before the meeting, except in the circumstance of emergency meetings, where the notice period may be reduced or waived.",
                isRequired: true,
                uniqueName: "R8Q1",
                entryName: "r8q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Every Director shall endeavor to attend all Board and Board Committee meetings in which he/she is a member. Any Director who does not attend at least 50% of all Board and Board Committee meetings during his/her tenure except in special circumstances shall not qualify for consideration for reelection.",
                isRequired: true,
                uniqueName: "R8Q2",
                entryName: "r8q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The quorum for Board meeting shall be clearly specified in the Memorandum and Articles of Association as well as the Board Charter.",
                isRequired: true,
                uniqueName: "R8Q3",
                entryName: "r8q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "E. Board Committees",
        description: "Recommended practices shall be as specified by Principle 11 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question:
                    "The Board shall establish structured Committees, which shall include Investment Strategy; Risk Management; Nomination & Governance andAudit Committees. However, the establishment of the Investment Strategy Committee shall not be applicable for Custody business.",
                isRequired: true,
                uniqueName: "R9Q1",
                entryName: "r9q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall ensure that each Committee comprises of Directors with relevant skills and competencies.",
                isRequired: true,
                uniqueName: "R9Q2",
                entryName: "r9q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Membership of each Committee shall comprise of at least three Directors.",
                isRequired: true,
                uniqueName: "R9Q3",
                entryName: "r9q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall review the performance of the Committees annually.",
                isRequired: true,
                uniqueName: "R9Q4",
                entryName: "r9q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "F. Board Nomination and Governance Committee",
        description: "",
        questions: [
            {
                question: "Members of the committee responsible for Nomination and Governance shall be NEDs and shall include at least one INED.",
                isRequired: true,
                uniqueName: "R10Q1",
                entryName: "r10q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Members of the Committee responsible for Nomination and Governance, shall also be responsible for Remuneration Committee function, as highlighted under Section 11.3 of Nigerian Code of Corporate Governance 2018.",
                isRequired: true,
                uniqueName: "R10Q2",
                entryName: "r10q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Committee shall meet at least once every quarter.",
                isRequired: true,
                uniqueName: "R10Q3",
                entryName: "r10q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Nomination and Governance Committee's responsibilities shall include: <br /> (i) Reviewing prospective candidates' qualifications and any potential conflict of interest, assessing the contributions of current Directors against their re-nomination suitability, and make appropriate recommendations to the Board. <br /> (ii) Review the structure, size and composition of the Board at least annually and make recommendations on any proposed changes.",
                isRequired: true,
                uniqueName: "R10Q4",
                entryName: "r10q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "G. Board Nomination and Governance Committee",
        description: "",
        questions: [
            {
                question:
                    "The responsibilities of the Audit Committee shall include: <br /> (i) Reviewing controls over financial reporting, information technology security and operational matters. <br /> (ii) Assessing the qualifications, independence and performance of the PFO's internal and external auditors. <br /> (iii) Obtaining appropriate external assurance and reporting on the design and operating effectiveness of the PFO's internal controls in its annual audited financial report. <br /> (iv) Ensuring that the scope of the audit plan is appropriate, risk based, and addresses major areas of concern, and that the audit plan is reviewed within appropriate frequency.",
                isRequired: true,
                uniqueName: "R11Q1",
                entryName: "r11q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Audit Committee shall have unrestricted access to the financial records of the PFOs including external auditors' reports.",
                isRequired: true,
                uniqueName: "R11Q2",
                entryName: "r11q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "H. Board Risk Management Committee",
        description: "",
        questions: [
            {
                question:
                    "The responsibilities of the Risk Management Committee shall include: <br /> (i) Determining the risk profile of the investment portfolios of the Pension Fund Administrator and Closed Pension Fund Administrator. <br /> (ii) Identifying, assessing and recommending to the Board acceptable levels of Risk. <br /> (iii) Drawing up programmes of adjustments in the case of deviation. <br /> (iv) Determining the level of reserves to cover the risks of the investment portfolios. <br /> (v) Advising the Board on Risk Strategies as well as reviewing and recommending the risk management policy and framework for the approval of the Board. <br /> (vi) Ensuring that risk awareness culture is maintained throughout the PFOs.",
                isRequired: true,
                uniqueName: "R12Q1",
                entryName: "r12q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Where a single Committee is responsible for audit and risk management, the Executives can only be in attendance",
                isRequired: true,
                uniqueName: "R12Q2",
                entryName: "r12q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall have at least one member having joint membership of both the Risk Management and Audit Committees for more effective functioning",
                isRequired: true,
                uniqueName: "R12Q3",
                entryName: "r12q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Chairman of the Committee shall be an INED",
                isRequired: true,
                uniqueName: "R12Q4",
                entryName: "r12q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Committee shall render a report of its activities to the PFO's Board and the Commission, at least once every quarter",
                isRequired: true,
                uniqueName: "R12Q5",
                entryName: "r12q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "I. Investment Strategy Committee",
        description: "",
        questions: [
            {
                question:
                    "The functions and responsibilities of the Investment Strategy Committee shall include: <br /> (i) Formulating investment strategies for the approval of the Board in line with Investment Regulations issued by the Commission. The strategies shall include a set of assumptions about the PFO's risk tolerance and the portfolios' expected returns. <br /> (ii) Monitoring and overseeing the implementation of the investment strategy. <br /> (iii) Establishing investment objectives and policies. <br /> (iv) Determining an optimal investment mix consistent with risk profile agreed by the Board of the PFOs. <br /> (v) Setting performance targets for the various pension funds under management. <br /> (vi) Evaluating the value of the daily marked to market portfolios and make proposals to the Board of the PFOs. <br /> (vii) Reviewing the performance of the major securities of the investment portfolios of the PFOs with the relevant benchmarks on periodic basic. <br /> (viii) Defining roles and responsibilities for Management including approval and authorization limits. <br /> (ix) Reviewing the level of the PFO's compliance with applicable investment laws and guidelines issued by the Commission. <br /> (x) Reviewing the level of the PFO's compliance with applicable investment laws and guidelines issued by the Commission. <br /> (xi) Periodically reviewing Investment policies and procedures.",
                isRequired: true,
                uniqueName: "R13Q1",
                entryName: "r13q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The person charged with the responsibility for Investment Management shall be a member of Senior Management of thePFOs, with relevant professional qualifications and experience; and shall be in attendance at meetings of the Committee",
                isRequired: true,
                uniqueName: "R13Q2",
                entryName: "r13q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Chairman of the Committee shall be a Non-Executive Director.",
                isRequired: true,
                uniqueName: "R13Q3",
                entryName: "r13q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "J. Appointment to the Board",
        description: "Recommended practices shall be as specified by Principle 12 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question: "The tenure for the MD/CEO and the EDs shall be determined by the Board but shall not exceed the maximum tenure provided in these Guidelines.",
                isRequired: true,
                uniqueName: "R14Q1",
                entryName: "r14q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "To resign, Directors shall submit a written notice of resignation addressed to the Chairman and the Commission.",
                isRequired: true,
                uniqueName: "R14Q2",
                entryName: "r14q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Where a Director has concerns about the running of the PFOs, which cannot be resolved and he elects to resign from the Board, such concerns shall be detailed in a written statement to the Chairman for circulation to the Board, with a copy to the Commission.",
                isRequired: true,
                uniqueName: "R14Q3",
                entryName: "r14q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "K. Induction and Training",
        description: "Recommended practices shall be as specified by Principle 12 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question:
                    "The Induction programme shall include: <br /> (i) The PFO's strategic objectives, products and services. <br /> (ii) The PFO's Memorandum and Articles of Association, Board procedures and matters reserved for the Board. <br /> (iii) The PFO's major risks and risk management strategies. <br /> (iv) Key performance indicators. <br /> (v) Regulatory requirements.",
                isRequired: true,
                uniqueName: "R15Q1",
                entryName: "r15q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "L. Board Evaluation",
        description: "Recommended practices shall be as specified by Principle 14 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question: "The Individual Evaluation shall aim to show the contributions of each Director to the Board, including time committed for Board and Board Committee meetings.",
                isRequired: true,
                uniqueName: "R16Q1",
                entryName: "r16q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "To bring objectivity to the process, the Board may consider the use of an external third party to conduct the evaluation.",
                isRequired: true,
                uniqueName: "R16Q2",
                entryName: "r16q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Where the use of an external third party is not desirable, the PFOs may decide to design and institute its own internal board review and evaluation process, and the following shall apply: <br /> (i) The Nomination and Governance Committee shall be responsible for evaluating performance; <br /> (ii) The NEDs, led by the INED, shall be responsible for performance evaluation of the Chairman, taking into account the views of the EDs; <br /> (iii) The Chairman and the NEDs shall be responsible for the evaluation of EDs; <br /> (iv) The Chairman and the EDs shall be responsible for the evaluation of NEDs <br /> (v) Details of such processes shall be forwarded to the Commission for prior approval.",
                isRequired: true,
                uniqueName: "R16Q3",
                entryName: "r16q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Chairman shall act on the results of the performance evaluation by recognizing the strengths and addressing the weaknesses of the Board and its Committees.",
                isRequired: true,
                uniqueName: "R16Q4",
                entryName: "r16q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The PFOs shall attach the outcome of the annual evaluation of the Board, Page Board Committees and Directors to its Corporate Governance Report to the Commission highlighted under 2.10.1.",
                isRequired: true,
                uniqueName: "R16Q5",
                entryName: "r16q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "M. Corporate Governance Evaluation",
        description: "Recommended practices shall be as specified by Principle 15 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question: "The annual Corporate Governance Evaluation Report shall also be forwarded to the Commission not later than three months after the PFO's financial year-end.",
                isRequired: true,
                uniqueName: "R17Q1",
                entryName: "r17q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Corporate Governance Report should include: <br /> (i) A description of Corporate Governance practices instituted in the PFOs within the reporting year. <br /> (ii) Disclosures of any deviation from the Corporate Governance Guidelines. <br /> (iii) Delegation of authority by the Board to any Board Committee to make decisions on any Board matters. <br /> (iv) The number of Board meetings held in the year and the attendance of every Board member at the meetings. <br /> (v) The number of Board Committee meetings held in the year and the attendance of every Board Committee member at the meetings. <br /> (vi) The terms of reference of all Board Committees. <br /> (vii) The type of material transactions that require Board approval under the PFO's internal Guidelines. <br /> (viii) The nature of Independent Director's relationship and the reason for considering him/her as independent, where the PFOs considers him/her independent despite the existence of a relationship as stated in 2.3.5.1 above that would otherwise deem him/her as non-independent. <br /> (ix) The relationship between the MD/CEO and the Chairman where they are related (spouse, child, step-child, brother, sister or parent). <br /> (x) The process for selection and appointment of new Directors to the Board. <br /> (xi) Key information regarding the Directors, i.e., which Directors are Executive, Non-Executive or considered by the Nomination and Governance Committee to be independent. <br /> (xii) The process of assessing the effectiveness of the Board as a whole and the contribution of each individual Director to the effectiveness of the Board. <br /> (xiii) Composition of the Board Committees and details of their activities. <br /> (xiv) The adequacy of internal controls, including financial, operational and compliance controls as well as risk management systems. <br /> (xv) How the performance evaluation of the Board, its Committees and its Directors was conducted.",
                isRequired: true,
                uniqueName: "R17Q2",
                entryName: "r17q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "N. Tenure and re-Election",
        description: "",
        questions: [
            {
                question:
                    "The tenure for the MD/CEO and the EDs shall not exceed a maximum of ten (10) years, while the tenure for an ED who proceeds to be MD/CEO shall not exceed a maximum of 15 years, inclusive of the years spent as ED.",
                isRequired: true,
                uniqueName: "R18Q1",
                entryName: "r18q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "NEDs shall serve for a maximum period of fifteen (15) years on the Board. However, it is necessary to reinforce the Board by continually injecting new energy, fresh ideas and perspectives. The Board should ensure the periodic appointment of new Directors to replace existing NEDs.",
                isRequired: true,
                uniqueName: "R18Q2",
                entryName: "r18q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "INEDs shall not serve on the Board for more than a maximum of 9 (nine) years comprising three terms of three years each.",
                isRequired: true,
                uniqueName: "R18Q3",
                entryName: "r18q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "No Chairman of a PFO can become the MD/CEO of the same PFO upon expiration of his/her tenure.",
                isRequired: true,
                uniqueName: "R18Q4",
                entryName: "r18q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "To ensure smooth transition in the PFOs, a grace period of two years from the date of the commencement of these Guidelines, shall apply where Board Member's aggregate or cumulative tenure has already exceeded tenure limit for such position.",
                isRequired: true,
                uniqueName: "R18Q5",
                entryName: "r18q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "O. Remuneration Governance",
        description: "Recommended practices shall be as specified by Principle 16 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question: "The Board should carefully consider compensation commitments to Directors in the event of early termination.",
                isRequired: true,
                uniqueName: "R19Q1",
                entryName: "r19q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Commission shall approve all new long-term incentive schemes for Directors.",
                isRequired: true,
                uniqueName: "R19Q2",
                entryName: "r19q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Director's letter of Appointment shall include remuneration package and method of remuneration.",
                isRequired: true,
                uniqueName: "R19Q3",
                entryName: "r19q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Where performance related elements is considered in remuneration of the EDs, it should be transparent, stretching and rigorously applied without bias, and care must be taken to prevent excessive risk taking.",
                isRequired: true,
                uniqueName: "R19Q4",
                entryName: "r19q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board may consider industry peer comparison or other benchmark for developing the Director's remuneration. However, such comparisons should be used with caution, to avoid paying more than necessary and the risk of upward notch in remuneration levels without corresponding improvement in corporate and individual performance.",
                isRequired: true,
                uniqueName: "R19Q5",
                entryName: "r19q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Remuneration structure should reflect the level of work expected of the EDs and NEDs and shall be in a manner that is consistent with appropriate incentives.",
                isRequired: true,
                uniqueName: "R19Q6",
                entryName: "r19q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board should include provisions that specify the circumstances where the PFOs would recover money paid or withhold payment of money to both Executive and Non-Executive Directors, and when it will be appropriate to do so.",
                isRequired: true,
                uniqueName: "R19Q7",
                entryName: "r19q7",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "P. Control Functions: Risk Management",
        description: "Recommended practices shall be as specified by Principle 17 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question:
                    "The Board should: Ensure that the risk management framework reflects the risk profile and appetite of the PFOs, clearly defining all risk elements and processes to identify and manage risks.",
                isRequired: true,
                uniqueName: "R20Q1",
                entryName: "r20q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board should: Ensure periodic review of the implementation of the risk management framework.",
                isRequired: true,
                uniqueName: "R20Q2",
                entryName: "r20q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board should: Re-evaluate the risk management process on a periodic basis to accommodate major changes.",
                isRequired: true,
                uniqueName: "R20Q3",
                entryName: "r20q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board should: The Head, Risk Management shall report administratively to the MD/CEO, and he/she shall have a functional reporting line to the Board Risk Management Committee.",
                isRequired: true,
                uniqueName: "R20Q4",
                entryName: "r20q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "Q. Control Functions: Compliance",
        description: "",
        questions: [
            {
                question: "The Board shall have the primary responsibility for the compliance function, as it shall oversee the management of the PFO's compliance risk.",
                isRequired: true,
                uniqueName: "R21Q1",
                entryName: "r21q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall approve the compliance policy of the PFOs and actively promote the values of honesty and integrity throughout the PFOs.",
                isRequired: true,
                uniqueName: "R21Q2",
                entryName: "r21q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board shall approve the appointment and if necessary dismiss a Compliance Officer subject to the approval of the Commission. In addition, the redeployment of a Compliance Officer shall be subject to the Commission's approval.",
                isRequired: true,
                uniqueName: "R21Q3",
                entryName: "r21q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "At least once a year, the Board or a Committee of the Board shall identify and assess the extent to which the Operator is managing its compliance risks.",
                isRequired: true,
                uniqueName: "R21Q4",
                entryName: "r21q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The PFOs shall employ a Compliance Officer whose responsibilities shall include: Ensuring compliance with the provisions of the PRA 2014, Guidelines, Rules and Regulations issued by the Commission as well as internal policies of the PFOs",
                isRequired: true,
                uniqueName: "R21Q5(a)",
                entryName: "r21q5a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "The PFOs shall employ a Compliance Officer whose responsibilities shall include: Having relevant professional and cognate experience.",
                isRequired: true,
                uniqueName: "R21Q5(b)",
                entryName: "r21q5b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "The PFOs shall employ a Compliance Officer whose responsibilities shall include: Reporting to the MD/CEO of the PFOs and the Commission on any noncompliance by the PFOs.",
                isRequired: true,
                uniqueName: "R21Q5(c)",
                entryName: "r21q5c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "The PFOs shall employ a Compliance Officer whose responsibilities shall include: Liaising with the Commission with regard to any matter which, in the opinion of the Commission, will enhance the compliance of the PFOs with the provisions of the PRA 2014, Guidelines, Rules and Regulations issued by the Commission. 3.2.10 The Regulation for Compliance Officers issued by the Commission remains valid and operational, as part of the practices of the Compliance function.",
                isRequired: true,
                uniqueName: "R21Q5(d)",
                entryName: "r21q5d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
        ],
    },
    {
        name: "",
        title: "R. Control Functions: Internal Audit",
        description: "Recommended practices shall be as specified by Principle 18 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question: "The Board shall articulate, implement and review the PFO's internal control systems to strengthen the risk management framework.",
                isRequired: true,
                uniqueName: "R22Q1",
                entryName: "r22q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board shall approve the appointment of the Head Internal Audit function subject to the approval of the Commission. The Board reserves the right to dismiss the Head, Internal Audit function, based on recommendation of the Board Audit Committee.",
                isRequired: true,
                uniqueName: "R22Q2",
                entryName: "r22q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Internal Audit function shall not be outsourced.",
                isRequired: true,
                uniqueName: "R22Q3",
                entryName: "r22q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "S. Whistleblowing",
        description: "",
        questions: [
            {
                question:
                    "The Board shall establish an effective whistle-blowing mechanism that will encourage stakeholders to bring unethical misconduct, violations of laws and regulations to the attention of an internal authority and/or the Commission. This will ensure that actions can be taken to verify the allegation and apply appropriate sanctions or take remedial actions to correct any harm done. The mechanism should be known to employees, as well as external stakeholders.",
                isRequired: true,
                uniqueName: "R23Q1",
                entryName: "r23q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board shall ensure that no whistle-blower is subject to any detriment on the grounds that he/her has made a disclosure in good faith. Where a whistleblower has been subjected to any detriment, he/she may present a complaint to the Commission. A whistle-blower who has suffered any detriment by reason of disclosure may be entitled to compensation and/or reinstatement as prescribed by the Commission.",
                isRequired: true,
                uniqueName: "R23Q2",
                entryName: "r23q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "T. External Auditors",
        description: "Recommended practices shall be as specified by Principle 20 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question:
                    "The Board shall engage a qualified External Auditor(s) and re-appoint or remove the Auditor based on the recommendation of the Board Audit Committee who shall be responsible for the external audit function of the PFOs.",
                isRequired: true,
                uniqueName: "R24Q1",
                entryName: "r24q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "There shall be a cooling off period of three years before a PFOs can engage any member of the audit team as a staff member in the financial reporting function.",
                isRequired: true,
                uniqueName: "R24Q2",
                entryName: "r24q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Where the Board is satisfied that the External Auditor has abused its office, acted in a fraudulent manner or colluded in any fraud or for any other reason, the Board may recommend the removal of such External Auditor in accordance with the provisions of extant laws, and a notice of the recommendation be forwarded to the Commission within 14 days.",
                isRequired: true,
                uniqueName: "R24Q3",
                entryName: "r24q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Where the Commission is satisfied that the External Auditor of the PFOs has abused its office as an auditor, it may require the PFOs to remove such External Auditor in line with the provisions of the extant laws.",
                isRequired: true,
                uniqueName: "R24Q4",
                entryName: "r24q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The External Auditor shall be responsible to the Commission for the protection of pension funds and assets managed or held by the PFOs it is auditing and shall report to the Commission on the following: <br /> (i) Any extreme situation such as evidence of imminent financial collapse of the PFOs. <br /> (ii) Any evidence of any event or occurrence, which has led or is likely to lead to material diminishing, of the net assets of the fund or the PFOs or both.",
                isRequired: true,
                uniqueName: "R24Q5",
                entryName: "r24q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board shall forward copies of the audited financial report to the Commission for approval together with the External Auditor's management letter on the audit of the fund and PFO, not later than four months from the PFOs/Fund's financial year-end.",
                isRequired: true,
                uniqueName: "R24Q6",
                entryName: "r24q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "U. Relationship with Shareholders: General Meetings",
        description: "Recommended practices shall be as specified by Principle 21 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question: "The meeting venue should be selected with consideration to shareholders with special disabilities.",
                isRequired: true,
                uniqueName: "R25Q1",
                entryName: "r25q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Notices of General Meetings shall be at least 21 days from the date on which the meeting is scheduled to hold. Copies of the Annual Reports, Audited Financial Statements and all other related documents pertaining to any resolution to be voted upon should be dispatched along with the notice of the meeting. This includes voting or proxy instructions and relevant papers that will enable stakeholders prepare adequately for the meeting.",
                isRequired: true,
                uniqueName: "R25Q2",
                entryName: "r25q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "U. Relationship with Shareholders: Shareholder Engagement",
        description: "",
        questions: [
            {
                question: "Recommended practices shall be as specified by Principle 22 of Nigerian Code of Corporate Governance 2018.",
                isRequired: true,
                uniqueName: "R26Q1",
                entryName: "r26q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "U. Relationship with Shareholders: Protection of Shareholder Right",
        description: "",
        questions: [
            {
                question: "Recommended practices shall be as specified by Principle 23 of Nigerian Code of Corporate Governance 2018.",
                isRequired: true,
                uniqueName: "R27Q1",
                entryName: "r27q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "V. Ethics and Business Practices: Conflict of Interest",
        description: "Recommended practices shall be as specified by Principle 24 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question:
                    "A relationship between a PFOs (PFA, CPFA, & PFC) and a third party service provider is deemed to exist where: i. The PFOs and the service providers, which it appoints, have common ownership or directorship, no matter how insignificant; and ii. An immediate family member (i.e. spouse, child, adopted child, stepchild, brother, sister or parent) of a shareholder or director of a PFOs is a shareholder or director of a service provider, which it appoints.",
                isRequired: true,
                uniqueName: "R28Q1",
                entryName: "r28q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "PFOs shall engage service providers that have a relationship with it on an arm's length basis and customary disclosures shall be made in their audited financial statements.",
                isRequired: true,
                uniqueName: "R28Q2",
                entryName: "r28q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Where the PFA decides to invest pension funds in a Company whose registrar is related (i.e. shareholder, subsidiary or associate) to either the PFA or the PFC which the PFA appoints, the PFA shall disclose such relationship to the Commission. In such circumstance, the Commission Page 27 of 30 Public reserves the right to allow or disallow such investment.",
                isRequired: true,
                uniqueName: "R28Q3",
                entryName: "r28q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "A relationship between a PFA and a PFC is deemed to exist in situations where: i. Any director in a PFA is an employee, principal officer or shareholder in a PFC that the PFA appoints to carry on any form of business on its behalf, in its subsidiary, associated PFOs, or its parent company; ii. Any director in a PFA that is a director on the Board of a PFC, and vice versa, that the PFOs appoints to carry on any form of business on its behalf, or is on the Board of its subsidiary or associated PFOs.",
                isRequired: true,
                uniqueName: "R28Q4",
                entryName: "r28q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The PFA shall not have any relationship with the PFC with which it chooses to do business.",
                isRequired: true,
                uniqueName: "R28Q5",
                entryName: "r28q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "To avoid potential conflict of interest, where applicable, PFOs employees shall declare their marital relation with employees of the Commission, and vice versa, immediately such relationships arise. Actions following disclosure shall be subject to the Commission's Conflict of Interest Policy.",
                isRequired: true,
                uniqueName: "R28Q6",
                entryName: "r28q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Employees shall at the earliest opportunity bring to the notice of their employers, in all cases, whereby conflict of interest arises in the discharge of their duties to the PFOs.",
                isRequired: true,
                uniqueName: "R28Q7",
                entryName: "r28q7",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Directors should promptly disclose any real or potential conflict of interest that they may have by virtue of their membership of the Board.",
                isRequired: true,
                uniqueName: "R28Q8",
                entryName: "r28q8",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "If a Director is not certain whether he is in a conflict of interest situation, the Director concerned should discuss the matter with the Chairman of the Board, the Company Secretary or the Chairman of the committee responsible for nomination and governance for advice and guidance.",
                isRequired: true,
                uniqueName: "R28Q9",
                entryName: "r28q9",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "If any question arises before the Board as to the existence of a real or perceived conflict, the Board or any Director, should report to the Commission to determine if a conflict exists.",
                isRequired: true,
                uniqueName: "R28Q10",
                entryName: "r28q10",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Directors who are aware of a real, potential or perceived conflict of interest on the part of a fellow Director, have a responsibility to raise the issue promptly for clarification, either with the Director concerned, the Chairman of the Board or the Chairman of the committee responsible for nomination and governance.",
                isRequired: true,
                uniqueName: "R28Q11",
                entryName: "r28q11",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "In the event that a Director becomes aware of any potential conflict of interest at any other point, he/she should disclose this to the Board and the PFOs should report to the Commission at the earliest possible opportunity.",
                isRequired: true,
                uniqueName: "R28Q12",
                entryName: "r28q12",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "No person who has served at directorate level or above, leaving the services of the Commission, for any reason, should be appointed as a Director or top management staff of a PFOs until after three years of the disengagement of such Executive or Senior Management staff.",
                isRequired: true,
                uniqueName: "R28Q13",
                entryName: "r28q13",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Employees shall do all in their power to ensure their impartiality and independence and avoid all situations that could result in a conflict of interest or be perceived as such. They should not use their position for the private gain of their spouse or children, or for the private gain of any other person.",
                isRequired: true,
                uniqueName: "R28Q14",
                entryName: "r28q14",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "V. Ethics and Business Practices: Ethical Culture",
        description: "Recommended practices shall be as specified by Principle 25 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question: "PFOs shall work in a spirit of respectful collaboration and co-operation with those with whom they interact without compromising their personal or professional integrity.",
                isRequired: true,
                uniqueName: "R29Q1",
                entryName: "r29q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "PFOs shall at all times, act honestly and in such manner that third parties are not caused to be misled. They shall not in the course of discharging their professional duties knowingly or recklessly disseminate false or misleading information to third parties.",
                isRequired: true,
                uniqueName: "R29Q2",
                entryName: "r29q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "PFOs shall not engage in any conduct involving dishonesty, fraud, deceit, or misrepresentation or commit any act that would negatively impact on the Pension industry.",
                isRequired: true,
                uniqueName: "R29Q3",
                entryName: "r29q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "PFOs shall not undermine other PFOs' stability in the process of marketing, so that competition is made healthy. PFOs shall not reveal information about other PFOs without their permission and shall take reasonable precautions to avoid such information from being disclosed unintentionally.",
                isRequired: true,
                uniqueName: "R29Q4",
                entryName: "r29q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Employees shall avoid directly or indirectly accepting or giving any gift, gratification, or anything of monetary value for anything done or omitted to be done during the discharge of their duties.",
                isRequired: true,
                uniqueName: "R29Q5",
                entryName: "r29q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Employees shall avoid directly or indirectly accepting or giving any gift, gratification, or anything of monetary value for anything done or omitted to be done during the discharge of their duties",
                isRequired: true,
                uniqueName: "R29Q6",
                entryName: "r29q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "PFOs shall never disclose in their writings, reports, social media posts or otherwise make public, any information they have acquired about persons, employers or clients in the course of their professional work, unless disclosure is both legal and that they have either taken reasonable steps to disguise the identity of the person, employer or client, or they have the express permission to disclose.",
                isRequired: true,
                uniqueName: "R29Q7",
                entryName: "r29q7",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Transactions must be recorded promptly and accurately in order to permit the preparation of accurate financial and other records. Records shall not be falsified in any manner. No entry shall be made that intentionally hides or disguises the true nature of any transaction.",
                isRequired: true,
                uniqueName: "R29Q8",
                entryName: "r29q8",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Employees shall act honestly, in good faith and in the best interests of the PFOs in accordance with legal requirements and agreed ethical standards",
                isRequired: true,
                uniqueName: "R29Q9",
                entryName: "r29q9",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Employees owe a fiduciary duty to the PFOs, and have a duty of care, skill, diligence and loyalty in fulfilling the functions of their offices and exercising the powers attached to those offices.",
                isRequired: true,
                uniqueName: "R29Q10",
                entryName: "r29q10",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "PFOs should encourage the reporting of unlawful or unethical behaviour and actively promote ethical behaviour and the protection of those who report violations in good faith.",
                isRequired: true,
                uniqueName: "R29Q11",
                entryName: "r29q11",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "W. Sustainability",
        description: "Recommended practices shall be as specified by Principle 26 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question: "0",
                isRequired: true,
                uniqueName: "R30Q0",
                entryName: 'r30q0',
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "X. Transparency (Stakeholder Communication)",
        description: "Recommended practices shall be as specified by Principle 27 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question: "0",
                isRequired: true,
                uniqueName: "R31Q0",
                entryName: 'r31q0',
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "X. Transparency (Disclosures)",
        description: "Recommended practices shall be as specified by Principle 27 of Nigerian Code of Corporate Governance 2018, in addition to the following sector specific provisions:",
        questions: [
            {
                question:
                    "The PFOs shall submit to the Commission, an annual report on both the company operations and pension funds being managed by it and the report shall include the audited accounts, not later than four months after its financial year end.",
                isRequired: true,
                uniqueName: "R32Q1",
                entryName: "r32q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The report shall include the description of corporate governance practices instituted in the PFOs within the reporting year and shall also disclose and explain any deviation from any provision of these Guidelines.",
                isRequired: true,
                uniqueName: "R32Q2",
                entryName: "r32q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "Y. Other documentation",
        description: "Add any other document to support/augment your report. Clicking “upload document” would allow you provide more context to each document.",
        questions: [
            {
                question: "",
                isRequired: false,
                uniqueName: "",
                entryName: "documentation",
                orderIndex: 1,
                dataType: 4,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
];
